<template>
    <div class="container">
        <div class="page-list">
            <div class="page-item" v-for="item in list" :style="{backgroundImage : 'url(http://oss.spjgzs.com/' + item.img_src + ')'}" @click="setCommodity(item)">
                <div class="page-info">
                    <div class="page-title">
                        {{item.name}}
                    </div>
                    <div class="page-price">
                        {{!!item.price?item.price+'元':'暂无'}}
                    </div>
                </div>
            </div>
            <div class="page-more" @click="getMore">{{tip}}</div>
        </div>
        <van-action-sheet
            v-model="show"
            :actions="actions"
            cancel-text="取消"
            close-on-click-action
            description="设置商品"
            @select="onSelect"
        />
    </div>
</template>
<script>
    export default {
        name: "commodityPrice",
        data() {
            return {
                params: {
                    num: 0
                },
                list: [],
                total: 0,
                tip: '加载更多',
                show: false,
                actions: [{ name: '隐藏' , subname: '点击隐藏',type:'hide'}],
                obj: null
            }
        },
        components: {

        },
        created() {
            this.getList()
        },
        mounted() {

        },
        methods: {
            setCommodity(obj) {
                this.obj = obj
                this.show = true
            },
            onSelect(item) {
                console.log(item)
                if (item.type === 'hide'){
                    console.log(this.obj)
                    let params = {
                        code: this.obj.code,
                        img_keyword: "香烟"
                    }
                    this.$util.ajax('/jh/manage/set', 'POST', params).then(res => {
                        if (res.code !== 200) {
                            this.$toast('查询失败')
                            return false
                        }
                        this.$toast('设置成功')
                    })
                }
            },
            getList() {
                let params = this.params
                this.$util.ajax('/code/commodity/list', 'POST', params).then(res => {
                    if (res.code !== 200) {
                        this.$toast('查询失败')
                        return false
                    }

                    this.total = res.total
                    if (params.num === 0){
                        this.list = res.value
                    }else {
                        this.list = this.list.concat(res.value)
                    }
                })
            },
            getMore() {
                if (this.list.length >= this.total){
                    this.tip = '已加载全部'
                    this.$toast('已加载全部')
                    return false
                }
                this.params.num ++
                this.getList()
            }
        }
    }
</script>

<style scoped lang="less">
    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 6px 0;
        background: #000000;
        .page-list {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .page-item {
                width: calc(50% - 3px);
                height: 280px;
                margin-bottom: 8px;
                background-size: cover;
                overflow: hidden;
                display: flex;
                align-items: flex-end;
                .page-info {
                    width: 100%;
                    padding: 6px;
                    background: rgba(255,255,255,0.2);
                    .page-title {
                        width: 100%;
                        display: flex;
                        font-size: 0.3rem;
                        color: #000000;
                        padding-bottom: 6px;
                    }
                    .page-price {
                        width: 100%;
                        font-size: 0.26rem;
                        color: red;
                    }
                }
            }
            .page-more {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 0;
                color: #fff;
                font-size: 0.24rem;
            }
        }
    }
</style>